const LOG_ENTRY_LENGTH_LIMIT = 1000;
const LOGS_KEY = 'consoleLogs';
// Function to store logs locally
function storeLogsLocally(logs) {
    const timestamp = new Date().toISOString();
    const logEntry = `${timestamp}: ${logs.length > LOG_ENTRY_LENGTH_LIMIT ? logs.substring(0, LOG_ENTRY_LENGTH_LIMIT).concat('...') : logs}`;
    // Store the log entry in a local variable or an array
    const storedLogs = localStorage.getItem(LOGS_KEY) ? JSON.parse(localStorage.getItem(LOGS_KEY)) : [];
    storedLogs.push(logEntry);
  
    // Store the updated logs in localStorage
    localStorage.setItem(LOGS_KEY, JSON.stringify(storedLogs));
  }

  export const deleteLogs = () => localStorage.setItem(LOGS_KEY, '[]');
  
  export default storeLogsLocally;