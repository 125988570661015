import * as Sentry from '@sentry/browser';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { clone } from 'lodash';
import { Button, Col, Form, InputNumber, Popconfirm, Row, Select, Switch, Table } from 'antd';
import styled from 'styled-components';
import { DoubleRightOutlined } from '@ant-design/icons';
import ActivityService from '../../../services/activity.service';
import CalendarService from '../../../services/calendar.service';
import { requestError } from '../../../helpers/notification';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import { LINK_TYPES } from '../../../constants/Links';
// import { ACTIVITY_STATE } from '../../../helpers/planning';

const DrawerRow = styled(Row)`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    padding-bottom: 60px;

    & > * {
        flex-grow: 1;
    }
`;

const NoTableRadio = styled.div`
    .ant-table-selection-column {
        display: none;
    }
`;

const LinkActionButton = styled.button`
    width: 32px;
    height: 32px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const LinksTab = ({ activity }) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'link_tab' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [predecessorLinks, setPredecessorLinks] = useState([]);
    const [successorLinks, setSuccessorLinks] = useState([]);
    const [selectedLink, setSelectedLink] = useState();
    const [activities, setActivities] = useState([]);
    const [linkCategories, setLinkCategories] = useState([]);
    const { activitiesDictionary, timeUnits, updateActivitiesDictionary, setRefreshGantt } = useContext(PlanningContext);
    const switchLinkTab = (record) => {
        const linkObj = window.ganttInstance.getLinkByServerId(record.id);
        const activityId = record.is_predecessor ? linkObj.source : linkObj.target;
        window.ganttInstance.hideLightbox();
        window.ganttInstance.unselectTask();
        window.ganttInstance.selectTask(activityId);
        window.ganttInstance.showLightbox(activityId, 'links');
    };
    const cannotEditLink = false;
    const LinkTypes = LINK_TYPES(i18n);
    const siblingsActivityTableColumns = [
        {
            title: t('activity'),
            dataIndex: 'activity',
        },
        {
            title: t('link'),
            dataIndex: 'type',
            render: (type) => LinkTypes[type].label
        },
        {
            title: t('gap'),
            dataIndex: 'decalage',
        },
        {
            title: t('unit'),
            dataIndex: 'day_definition',
        },
        {
            title: '',
            dataIndex: 'action',
            render: (text, record) => <Button icon={<DoubleRightOutlined />} onClick={() => switchLinkTab(record)} />,
        },
    ];

    // const checkValidateLocked = (successorId) => {
    //     const successorTask = window.ganttInstance.getTask(successorId);
    //     if (successorTask.locked) {
    //         return true
    //     }
    //     if (successorTask.status === ACTIVITY_STATE.VALID) {
    //         return true
    //     }
    //     return false;
    // }

    const submitForm = (values) => {
        if (saving) {
            return;
        }
        // const successorId = selectedLink.is_predecessor ? activity.id : values.activityId;
        // block if successor activity is locked or valid
        // if (checkValidateLocked(successorId)) {
        //     notificationError('Liens', "L'activité successeur est validée ou verrouillée")
        //     return;
        // }
        setSaving(true);

        ActivityService.createLink(
            selectedLink.is_predecessor ? values.activityId : activity.serverId,
            selectedLink.is_predecessor ? activity.serverId : values.activityId,
            values.decalage,
            values.type,
            values.dayDefinitionId,
            values.categoryId
        )
            .then((response) => {
                setSaving(false);

                const newLink = {
                    ...response,
                    activity: activitiesDictionary[values.activityId]?.name,
                    day_definition: timeUnits.find((item) => item.id === values.dayDefinitionId)?.name,
                    is_predecessor: selectedLink.is_predecessor,
                };
                const sourceActivity = activitiesDictionary[newLink.activityPredecessorId];
                const targetActivity = activitiesDictionary[newLink.activitySuccessorId];
                updateActivitiesDictionary(newLink.activityPredecessorId, {
                    successorLinksId: [...sourceActivity.successorLinksId, newLink.id],
                });
                updateActivitiesDictionary(newLink.activitySuccessorId, {
                    predecessorLinksId: [...targetActivity.predecessorLinksId, newLink.id],
                });
                // update link in gantt
                setRefreshGantt((val) => val + 1);

                if (selectedLink.is_predecessor) {
                    setPredecessorLinks((origin) => [...origin, newLink]);
                } else {
                    setSuccessorLinks((origin) => [...origin, newLink]);
                }

                setSelectedLink(null);
                form.resetFields();
            })
            .catch((error) => {
                Sentry.captureException(error);
                requestError(error, t('error_save_link'));
                setSaving(false);
            });
    };

    const saveField = (uriParameter, field, value) => {
        if (selectedLink.is_creation) {
            return;
        }
        // if (field === 'activitySuccessorId') {

        //     const successorId = value;
        //     // block if successor activity is locked or valid
        //     if (checkValidateLocked(successorId)) {
        //     notificationError('Liens', "L'activité successeur est validée ou verrouillée")

        //         return;
        //     }
        // }

        ActivityService.updateLink(selectedLink.id, uriParameter, field, value)
            .then((response) => {
                setSelectedLink((origin) => ({ ...origin, [field]: value }));
                const sourceActivity = activitiesDictionary[response.activityPredecessorId];
                const targetActivity = activitiesDictionary[response.activitySuccessorId];
                updateActivitiesDictionary(response.activityPredecessorId, {
                    successorLinksId: [...sourceActivity.successorLinksId, response.id],
                });
                updateActivitiesDictionary(response.activitySuccessorId, {
                    predecessorLinksId: [...targetActivity.predecessorLinksId, response.id],
                });
                if (selectedLink.is_predecessor) {
                    setPredecessorLinks((origin) => {
                        const oldData = origin;
                        const recordToUpdateIndex = oldData.findIndex((i) => i.id === response.id);
                        if (recordToUpdateIndex !== -1) {
                            oldData[recordToUpdateIndex] = {
                                ...response,
                                activity: activitiesDictionary[response.activityPredecessorId].name,
                                day_definition: timeUnits.find((item) => item.id === response.dayDefinitionId)?.name,
                                is_predecessor: selectedLink.is_predecessor,
                            };
                        }
                        return clone(oldData);
                    });
                } else {
                    setSuccessorLinks((origin) => {
                        const oldData = origin;
                        const recordToUpdateIndex = oldData.findIndex((i) => i.id === response.id);
                        if (recordToUpdateIndex !== -1) {
                            oldData[recordToUpdateIndex] = {
                                ...response,
                                activity: activitiesDictionary[response.activitySuccessorId].name,
                                day_definition: timeUnits.find((item) => item.id === response.dayDefinitionId)?.name,
                                is_predecessor: selectedLink.is_predecessor,
                            };
                        }
                        return clone(oldData);
                    });
                }
                // update on gantt
                setRefreshGantt((val) => val + 1);
            })
            .catch((error) => {
                Sentry.captureException(error);
                requestError(error, generalTranslation('generic_error'));
            });
    };

    const selectRow = (record) => {
        if (selectedLink?.id === record.id) {
            setSelectedLink(null);
            form.resetFields();
        } else {
            setSelectedLink(record);
            // TODO deactivate when grouping
            const linkObj = window.ganttInstance.getLinkByServerId(record.id);
            window.ganttInstance.showTask(record.is_predecessor ? linkObj.source : linkObj.target);
            form.setFieldsValue({
                activityId: record.is_predecessor ? record.activityPredecessorId : record.activitySuccessorId,
                type: record.type,
                decalage: record.decalage,
                dayDefinitionId: record.dayDefinitionId,
                categoryId: record.category.id,
                nextCalendar: record.nextCalendar,
                actived: record.actived,
                used: !record.used,
                planified: record.planified,
            });
        }
    };

    const onSelectedRowChange = (keys) => {
        if (keys.length > 0) {
            setSelectedLink(keys[0]);
        }
    };

    const deleteLink = () => {
        try {
            window.ganttInstance.deleteLink(window.ganttInstance.getLinkByServerId(selectedLink.id).id);
            window.ganttInstance.refreshData();
            if (selectedLink.is_predecessor) {
                setPredecessorLinks((origin) => origin.filter((link) => link.id !== selectedLink.id));
            } else {
                setSuccessorLinks((origin) => origin.filter((link) => link.id !== selectedLink.id));
            }
            setSelectedLink(null);
            form.resetFields();
        } catch (error) {
            Sentry.captureException(error);
            requestError(error, generalTranslation('generic_error'));
        }
    };

    const createLink = (isPredecessor) => {
        form.resetFields();
        setSelectedLink({
            is_creation: true,
            is_predecessor: isPredecessor,
            type: 'FD',
            decalage: 0,
            dayDefinitionId: null,
            categoryId: null,
            nextCalendar: false,
            used: true,
        });
    };

    useEffect(() => {
        const onActivityChange = async () => {
            setPredecessorLinks([]);
            setSuccessorLinks([]);
            setSelectedLink(null);
            setLoading(true);
            const activityData = await ActivityService.showActivity(activity.serverId);
            Promise.all([
                await Promise.all(
                    activityData.predecessorLinksId.map((predecessorLinkId) =>
                        ActivityService.showLink(predecessorLinkId)
                    )
                ),
                await Promise.all(
                    activityData.successorLinksId.map((successorLinkId) => ActivityService.showLink(successorLinkId))
                ),
            ])
                .then(([predecessors, successors]) => {
                    predecessors.forEach(async (link) => {
                        const predecessorActivity = activitiesDictionary[link.activityPredecessorId];
                        const predecessorDayDefinition = await CalendarService.showDayDefinition(link.dayDefinitionId);
                        setPredecessorLinks((origin) => [
                            ...origin,
                            {
                                ...link,
                                activity: predecessorActivity.name,
                                day_definition: predecessorDayDefinition.name,
                                is_predecessor: true,
                            },
                        ]);
                    });

                    successors.forEach(async (link) => {
                        const successorActivity = activitiesDictionary[link.activitySuccessorId];
                        const successorDayDefinition = await CalendarService.showDayDefinition(link.dayDefinitionId);

                        setSuccessorLinks((origin) => [
                            ...origin,
                            {
                                ...link,
                                activity: successorActivity.name,
                                day_definition: successorDayDefinition.name,
                                is_predecessor: false,
                            },
                        ]);
                    });
                    // using dictionnary
                    setActivities(
                        Object.values(activitiesDictionary)
                            .filter(
                                (subActivity) =>
                                    subActivity.subActivitiesId.length === 0 && subActivity.id !== activity.id
                            )
                            .map((subActivity) => ({ label: subActivity.name, value: subActivity.id }))
                    );

                    // CalendarService.listTimeUnits()
                    //     .then((response) =>
                    //         setTimeUnits(response.map((timeUnit) => ({ label: timeUnit.name, value: timeUnit.id })))
                    //     )
                    //     .catch((error) => requestError(error, 'Erreur lors de la récupération les unités de temps'));

                    ActivityService.listLinkCategories()
                        .then((response) => {
                            setLinkCategories(
                                response.map((linkCategory) => ({ label: linkCategory.name, value: linkCategory.id }))
                            );
                            setLoading(false);
                        })
                        .catch((error) => {
                            Sentry.captureException(error);
                            requestError(error, t('error_get_link_type'));
                        });
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    requestError(error, t('error_get_link'));
                });
        };
        onActivityChange();
    }, [activity]);

    return (
        <>
            <DrawerRow>
                <div className="mt-3">
                    <div className="flex justify-between items-end mb-2">
                        <h5>{t('predecessors')}</h5>
                        <div className="flex">
                            <LinkActionButton
                                type="button"
                                className={`flex items-center justify-center bg-primary mr-2 ${
                                    (selectedLink || (cannotEditLink))
                                        ? 'opacity-50'
                                        : ''
                                }`}
                                disabled={selectedLink || cannotEditLink}
                                onClick={() => createLink(true)}
                            >
                                <PlusSvg />
                            </LinkActionButton>
                            <Popconfirm
                                title={t('delete_link_text')}
                                okText={generalTranslation('yes')}
                                cancelText={generalTranslation('no')}
                                onConfirm={deleteLink}
                            >
                                <LinkActionButton
                                    type="button"
                                    className={`flex items-center justify-center mr-2 ${
                                        (selectedLink && selectedLink.is_predecessor && !selectedLink.is_creation)
                                            ? 'bg-primary'
                                            : 'bg-secondary text-white'
                                    }`}
                                    disabled={
                                        !(selectedLink && selectedLink.is_predecessor && !selectedLink.is_creation)
                                    }
                                >
                                    <CloseSvg />
                                </LinkActionButton>
                            </Popconfirm>
                        </div>
                    </div>
                    <NoTableRadio>
                        <Table
                            columns={siblingsActivityTableColumns}
                            dataSource={predecessorLinks}
                            loading={loading}
                            rowKey="id"
                            onRow={(record) => ({
                                onClick: () => (!cannotEditLink) && selectRow(record),
                            })}
                            rowSelection={{
                                type: 'radio',
                                selectedRowKeys: [selectedLink?.id],
                                onChange: onSelectedRowChange,
                            }}
                        />
                    </NoTableRadio>
                </div>
                <div className="mt-3">
                    <div className="flex justify-between items-end mb-2">
                        <h5>{t('successors')}</h5>
                        <div className="flex">
                            <LinkActionButton
                                type="button"
                                className={`flex items-center justify-center bg-primary mr-2 ${
                                    (selectedLink || (cannotEditLink))
                                        ? 'opacity-50'
                                        : ''
                                }`}
                                disabled={selectedLink || cannotEditLink}
                                onClick={() => createLink(false)}
                            >
                                <PlusSvg />
                            </LinkActionButton>
                            <Popconfirm
                                title={t('delete_link_text')}
                                okText={generalTranslation('yes')}
                                cancelText={generalTranslation('no')}
                                onConfirm={deleteLink}
                            >
                                <LinkActionButton
                                    type="button"
                                    className={`flex items-center justify-center mr-2 ${
                                        (selectedLink && !selectedLink.is_predecessor && !selectedLink.is_creation)
                                            ? 'bg-primary'
                                            : 'bg-secondary text-white'
                                    }`}
                                    disabled={
                                        !(selectedLink && !selectedLink.is_predecessor && !selectedLink.is_creation)
                                    }
                                >
                                    <CloseSvg />
                                </LinkActionButton>
                            </Popconfirm>
                        </div>
                    </div>
                    <NoTableRadio>
                        <Table
                            columns={siblingsActivityTableColumns}
                            dataSource={successorLinks}
                            rowKey="id"
                            loading={loading}
                            onRow={(record) => ({
                                onClick: () => !cannotEditLink && selectRow(record),
                            })}
                            rowSelection={{
                                type: 'radio',
                                selectedRowKeys: [selectedLink?.id],
                                onChange: onSelectedRowChange,
                            }}
                        />
                    </NoTableRadio>
                </div>
                {selectedLink && (
                    <Form
                        form={form}
                        layout="vertical"
                        name="link_form"
                        initialValues={selectedLink}
                        onFinish={submitForm}
                        className="mt-3"
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={selectedLink.is_predecessor ? t('predecessor') : t('successor')}
                                    name="activityId"
                                >
                                    <Select
                                        disabled={cannotEditLink}
                                        options={activities}
                                        onChange={(value) =>
                                            saveField(
                                                selectedLink.is_predecessor
                                                    ? 'activity-predecessor'
                                                    : 'activity-successor',
                                                selectedLink.is_predecessor
                                                    ? 'activityPredecessorId'
                                                    : 'activitySuccessorId',
                                                value
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label={generalTranslation('type')} name="type">
                                    <Select
                                        options={Object.values(LinkTypes)}
                                        onChange={(value) => saveField('link-type', 'linkType', value)}
                                        disabled={cannotEditLink}

                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label={t('gap')} name="decalage">
                                    <InputNumber
                                        step={1}
                                        disabled={cannotEditLink}
                                        type="number"
                                        onBlur={(event) => saveField('decalage', 'decalage', event.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item label={t('gap_unit')} name="dayDefinitionId">
                                    <Select
                                        disabled={cannotEditLink}
                                        options={timeUnits.map((timeUnit) => ({
                                            label: timeUnit.name,
                                            value: timeUnit.id,
                                        }))}
                                        onChange={(value) => saveField('day-definition-id', 'dayDefinitionId', value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label={t('link_category')} name="categoryId">
                                    <Select
                                        disabled
                                        options={linkCategories}
                                        onChange={(value) => saveField('link-category', 'linkCategoryId', value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label={t('successor_calendar')} name="nextCalendar">
                                    <Switch
                                        onChange={(value) => saveField('is-next-calendar', 'nextCalendar', value)}
                                        defaultChecked={selectedLink.nextCalendar}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={t('active_link')} name="actived">
                                    <Switch disabled defaultChecked={selectedLink.actived} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label={t('skip_link')} name="used">
                                    <Switch
                                        onChange={(value) => saveField('used', 'used', !value)}
                                        defaultChecked={!selectedLink.used}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={t('scheduled')} name="planified">
                                    <Switch disabled defaultChecked={selectedLink.planified} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </DrawerRow>
            {selectedLink && selectedLink.is_creation && (
                <div
                    className="absolute right-0 bottom-0 w-full bg-white text-right"
                    style={{ 
                        borderTop: '1px solid #e9e9e9', 
                        padding: '10px 16px',
                        zIndex: 1
                    }}
                >
                    <Button loading={saving} onClick={() => setSelectedLink(null)} style={{ marginRight: 8 }}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={saving} onClick={() => form.submit()} type="primary">
                        {generalTranslation('save')}
                    </Button>
                </div>
            )}
        </>
    );
};

LinksTab.propTypes = {
    activity: PropTypes.object.isRequired,
};

export default LinksTab;
