import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

const ColorBox = styled.div`
    width: 35px;
    height: 25px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
`;

const CustomColorPicker = ({ color, onChange, disabled, label }) => {
    const handleChangeComplete = (newColor) => {
        const { r, g, b, a } = newColor.rgb;
        const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
            .toString(16)
            .padStart(2, '0')}`;
        const alphaHex = Math.round(a * 255)
            .toString(16)
            .padStart(2, '0');
        const colorWithAlpha = `${hexColor}${alphaHex}`;
        onChange(colorWithAlpha);
    };

    const content = <SketchPicker color={color} onChangeComplete={handleChangeComplete} disableAlpha={false} />;

    return (
        <div className="w-full">
            {label && <label className="pr-2 w-full">{label}</label>}
            {disabled ? (
                <ColorBox style={{ backgroundColor: color }} />
            ) : (
                <Popover content={content} trigger="click" placement="bottomLeft" overlayInnerStyle={{ padding: 0 }}>
                    <ColorBox style={{ backgroundColor: color }} />
                </Popover>
            )}
        </div>
    );
};

CustomColorPicker.propTypes = {
    color: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
};

CustomColorPicker.defaultProps = {
    label: null,
    disabled: false,
};

export default CustomColorPicker;
