import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { requestError } from '../../../helpers/notification';
import CustomColorPicker from '../../utils/CustomColorPicker';


const DayDefinitionEditionForm = ({ dayDefinition }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_days' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [align] = useState('center');
    const { backToMainModal, refreshDayDefinitions } = useContext(CalendarMenuContext);
    const [entityColor, setEntityColor] = useState(dayDefinition ? dayDefinition.color : '#ffffff');

    const createDayDefinition = (values) => {
        setLoading(true);
        CalendarService.createDayDefinition(values.name, entityColor)
            .then(() => {
                setLoading(false);
                backToMainModal(refreshDayDefinitions);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('day_create_error'));
            });
    };

    const updateDayDefinition = (values) => {
        setLoading(true);
        CalendarService.updateDayDefinitionName(dayDefinition, values.name)
            .then(() => {
                CalendarService.updateDayDefinitionColor(dayDefinition, entityColor)
                    .then(() => {
                        setLoading(false);
                        backToMainModal(refreshDayDefinitions);
                    })
                    .catch((error) => {
                        setLoading(false);
                        requestError(error, t('day_color_updating_error'));
                    });
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('day_name_updating_error'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }
        if (dayDefinition) {
            updateDayDefinition(values);
        } else {
            createDayDefinition(values);
        }
    };
    const entity = {
        name: dayDefinition ? dayDefinition.name : null,
    };

    const updateEntityColor = (color) => {
        setEntityColor(color);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="day_definition_edit_form"
            initialValues={entity}
            onFinish={submitForm}
        >
            <Space align={align}>
                <Form.Item label={generalTranslation('name')} name="name" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={generalTranslation('color')}>
                <CustomColorPicker
                            color={entityColor}
                            onChange={updateEntityColor}
                        />
                </Form.Item>
            </Space>
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

DayDefinitionEditionForm.propTypes = {
    dayDefinition: PropTypes.object,
};

DayDefinitionEditionForm.defaultProps = {
    dayDefinition: null,
};

export default DayDefinitionEditionForm;
