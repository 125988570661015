import React, { useContext, useEffect, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
/* eslint no-unused-vars: "off" */
import { Row, Col, Form, Checkbox, Input, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import FieldSetting from './FieldSetting';
import { UserContext } from '../../../contexts/app/UserContext';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import ActivityService from '../../../services/activity.service';
import { requestError } from '../../../helpers/notification';
import { getPlanningAccess, USER_ACCESS } from '../../../helpers/planning';
import { RESOURCE_VIEW_MODE } from '../../../constants/Generic';
import CustomColorPicker from '../../utils/CustomColorPicker';

const defaultParameters = {
    leftTextColumn: 'empty',
    leftTextColor: '#000000',
    centerTextColumn: 'text',
    centerTextColor: '#FFFFFF',
    rightTextColumn: 'empty',
    rightTextColor: '#000000',
    milestoneRightTextColor: '#000000',
    milestoneRightTextColumn: 'empty',
    milestoneLeftTextColor: '#000000',
    milestoneLeftTextColumn: 'empty',
    showLinks: true,
    showCriticalPath: false,
    criticalPathColor: 'red',
    showFreeSlack: false,
    freeSlackColor: 'lightgrey',
    showTotalSlack: false,
    totalSlackColor: 'lightgrey',
    hideHours: true,
    fontFamily: 'Lato',
    showNoWorkingDay: true,
    showResources: 'nothing',
    parentTaskBarColor: '#fbbe3f',
    simpleTaskBarColor: '#828282',
    milestoneColor: '#828282',
};

const cardHeadStyle = {
    background: 'unset',
    fontWeight: 'bold',
    borderBottom: 'unset',
};
const standaloneSettingStyle = {
    paddingLeft: '2rem',
};

const cardBodyStyle = { paddingBottom: '0px', paddingLeft: '2rem' };

// This component is used for customizing Gantt appearance or behaviour

const GanttSettingGlobal = ({ tabPreferences, setNewPreferences }) => {
    const [form] = Form.useForm();
    const [idPrefix, setIdPrefix] = useState('');
    const [parameters, setParameters] = useState(defaultParameters);
    const parametersRef = useRef(null);
    // colors
    const { currentUser } = useContext(UserContext);
    const { planningSelected } = useContext(PlanningContext);
    const { i18n } = useTranslation();
    const { t } = useTranslation('translation', { keyPrefix: 'gantt_setting' });

    const planningAccess = getPlanningAccess(planningSelected, currentUser);

    useEffect(() => {
        parametersRef.current = parameters;
        const newPreferences = { ...tabPreferences, gantt_parameters: parametersRef.current };
        setNewPreferences(newPreferences);
    }, [parameters]);

    useEffect(() => {
        const onPreferenceChange = async () => {
            if (tabPreferences?.gantt_parameters) {
                setParameters(() => {
                    const newValue = {
                        ...defaultParameters,
                        ...tabPreferences?.gantt_parameters,
                    };
                    // setOldParameters(newValue);
                    return newValue;
                });
            }
            if (planningSelected) {
                const prefixId = await ActivityService.getIdPrefix(planningSelected.rootActivityId);
                setIdPrefix(prefixId.prefixId);
            }
        };
        onPreferenceChange();
    }, [planningSelected]);

    /* eslint-disable react/prop-types */
    const handleChangeIdPrefix = async (value) => {
        try {
            await ActivityService.changeIdPrefix(planningSelected.rootActivityId, value);
            setIdPrefix(value);
        } catch (error) {
            requestError(error, t('id_prefix_change'));
        }
    };

    return (
        <Form
            form={form}
            layout="horizontal"
            name="planning_parameter_display"
            initialValues={parameters}
            style={{ minWidth: '650px' }}
        >
            <div style={{ height: '60vh', overflowY: 'auto' }}>
                {(planningAccess === USER_ACCESS.ADMIN || planningAccess === USER_ACCESS.OWNER) && (
                    <Row className="mb-2" style={standaloneSettingStyle}>
                        <Col span={14}>{t('id_prefix')}</Col>
                        <Col span={10}>
                            <Input
                                type="text"
                                value={idPrefix}
                                onChange={(e) => setIdPrefix(e.target.value)}
                                onBlur={(e) => handleChangeIdPrefix(e.target.value)}
                            />
                        </Col>
                    </Row>
                )}
                <Row style={standaloneSettingStyle}>
                    <Col span={14}>{t('show_needs')} </Col>
                    <Col span={10}>
                        <p style={{ marginBottom: '20px' }}>
                            <Checkbox
                                checked={parameters.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value}
                                className="font-thin"
                                style={{ fontFamily: 'inherit !important' }}
                                onChange={(e) => {
                                    setParameters((old) => ({
                                        ...old,
                                        showResources: e.target.checked
                                            ? RESOURCE_VIEW_MODE(i18n).NEEDS.value
                                            : RESOURCE_VIEW_MODE(i18n).NOTHING.value,
                                    }));
                                }}
                            />
                        </p>
                    </Col>
                </Row>
                <Card size="small" title={t('links')} headStyle={cardHeadStyle} bodyStyle={cardBodyStyle} bordered>
                    <Row>
                        <Col span={14}>{t('show_links')} </Col>
                        <Col span={10}>
                            <p style={{ marginBottom: '20px' }}>
                                <Checkbox
                                    checked={parameters.showLinks}
                                    className="font-thin"
                                    style={{ fontFamily: 'inherit !important' }}
                                    onChange={(e) => {
                                        setParameters((old) => ({ ...old, showLinks: e.target.checked }));
                                    }}
                                />
                            </p>
                        </Col>
                    </Row>
                    <FieldSetting
                        title={t('show_critical_path')}
                        field="showCriticalPath"
                        colorField="criticalPathColor"
                        isColumn={false}
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                </Card>

                <Card size="small" title={t('margins')} headStyle={cardHeadStyle} bodyStyle={cardBodyStyle} bordered>
                    <FieldSetting
                        title={t('show_free_slack')}
                        field="showFreeSlack"
                        colorField="freeSlackColor"
                        isColumn={false}
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                    <FieldSetting
                        title={t('show_total_slack')}
                        field="showTotalSlack"
                        colorField="totalSlackColor"
                        isColumn={false}
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                </Card>

                <Card size="small" title={t('time')} headStyle={cardHeadStyle} bodyStyle={cardBodyStyle} bordered>
                    <Row>
                        <Col span={14}>{t('dither_no_working_days')} </Col>
                        <Col span={10}>
                            <p style={{ marginBottom: '20px' }}>
                                <Checkbox
                                    checked={parameters.showNoWorkingDay}
                                    className="font-thin"
                                    style={{ fontFamily: 'inherit !important' }}
                                    onChange={(e) => {
                                        setParameters((old) => ({ ...old, showNoWorkingDay: e.target.checked }));
                                    }}
                                />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={14}>{t('show_hours')} </Col>
                        <Col span={10}>
                            <p style={{ marginBottom: '20px' }}>
                                <Checkbox
                                    checked={!parameters.hideHours}
                                    className="font-thin"
                                    style={{ fontFamily: 'inherit !important' }}
                                    onChange={(e) => {
                                        setParameters((old) => ({ ...old, hideHours: !e.target.checked }));
                                    }}
                                />
                            </p>
                        </Col>
                    </Row>
                </Card>

                <Card size="small" title={t('taskbar')} headStyle={cardHeadStyle} bodyStyle={cardBodyStyle} bordered>
                    <Row>
                        <Col span={14}>{t('parent_taskbar_color')} </Col>
                        <Col span={10}>
                            <Form.Item className="w-2/3">
                                <CustomColorPicker
                                    color={parameters.parentTaskBarColor}
                                    onChange={(color) =>
                                        setParameters((old) => ({ ...old, parentTaskBarColor: color }))
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={14}>{t('simple_taskbar_color')} </Col>
                        <Col span={10}>
                            <Form.Item className="w-2/3">
                            <CustomColorPicker
                                    color={parameters.simpleTaskBarColor}
                                    onChange={(color) =>
                                        setParameters((old) => ({ ...old, simpleTaskBarColor: color }))
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <FieldSetting
                        title={t('left_taskbar_text')}
                        field="leftTextColumn"
                        colorField="leftTextColor"
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                    <FieldSetting
                        title={t('taskbar_text')}
                        field="centerTextColumn"
                        colorField="centerTextColor"
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                    <FieldSetting
                        title={t('right_taskbar_text')}
                        field="rightTextColumn"
                        colorField="rightTextColor"
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                </Card>
                <Card size="small" title={t('milestone')} headStyle={cardHeadStyle} bodyStyle={cardBodyStyle} bordered>
                <Row>
                        <Col span={14}>{t('milestone_color')} </Col>
                        <Col span={10}>
                            <Form.Item className="w-2/3">
                            <CustomColorPicker
                                    color={parameters.milestoneColor}
                                    onChange={(color) =>
                                        setParameters((old) => ({ ...old, milestoneColor: color }))
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <FieldSetting
                        title={t('left_milestone_text')}
                        field="milestoneLeftTextColumn"
                        colorField="milestoneLeftTextColor"
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                    <FieldSetting
                        title={t('right_milestone_text')}
                        field="milestoneRightTextColumn"
                        colorField="milestoneRightTextColor"
                        parameters={parameters}
                        setParameters={setParameters}
                    />
                </Card>
            </div>
        </Form>
    );
};

GanttSettingGlobal.propTypes = {
    setNewPreferences: PropTypes.func.isRequired,
    tabPreferences: PropTypes.object.isRequired,
};
export default GanttSettingGlobal;
