/* eslint no-unused-vars: "off" */
import React, { useContext, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Tooltip, Select, Divider, Checkbox } from 'antd';
import { isArray } from 'lodash';
import styled from 'styled-components';
import { ReactComponent as CompareSvg } from '../../assets/icons/compare.svg';

import { PlanningContext } from '../../contexts/app/PlanningContext';
import LoadingSpin from '../utils/LoadingSpin';
import { TabContext } from '../../contexts/app/TabContext';
import BaseLineExplorerModal from '../explorer/BaseLineExplorerModal';
import folderService from '../../services/folder.service';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
    transform: rotate(90deg);
`;

const DropdownContainer = styled.div`
    width: 300px;
`;

const DropdownBody = styled.div``;

const PanelTitle = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
    font-size: 15px;
    line-height: 18px;
`;

const BaseLineDropdown = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'baseline_dropdown' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [planningArchives, setPlanningArchives] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    // for rerendering select
    const [selectValue, setSelectValue] = useState(null);
    const { tabPreferences, updateTabPreferences } = useContext(TabContext);
    const { planningSelected, refreshPlanningSelected } = useContext(PlanningContext);
    const [showBaseLineLayer, setShowBaseLineLayer] = useState(false);
    const [choosenBaseLinePlanning, setChoosenBaseLinePlanning] = useState(null);
    const [currentBaseLinePlanning, setCurrentBaseLinePlanning] = useState(null);
    const explorerModalRef = useRef();

    const setDefaultState = () => {
        const baseLinePreference = tabPreferences?.gantt_baseline;
        if (baseLinePreference) {
            setCurrentBaseLinePlanning(baseLinePreference.baseLinePlanning);
            setShowBaseLineLayer(baseLinePreference.showBaseLine);
        }
        setChoosenBaseLinePlanning(null);
    };

    useEffect(() => {
        setDefaultState();
        if (planningSelected) {
            const getRelatedPlanning = async () => {
                const archives = await folderService.getRelatedPlanning(planningSelected.id);
                if (isArray(archives) && archives?.length > 0) {
                    setPlanningArchives(archives.filter((planning) => planning.id !== planningSelected.id));
                }
            };
            getRelatedPlanning();
        }
    }, [planningSelected]);

    const handlePlanningChosen = (planning) => {
        setIsVisible(true);
        setShowBaseLineLayer(true);
        setChoosenBaseLinePlanning(planning);
        setSelectValue(null);
    };

    const handleArchiveSelection = (val) => {
        if (val) {
            setSelectValue(val);
            const archive = planningArchives.find((i) => i.id === val);
            setShowBaseLineLayer(true);
            setChoosenBaseLinePlanning(null);
        } else {
            setShowBaseLineLayer(false);
            setSelectValue(null);
        }
    };

    const handleOpenExplorer = () => {
        if (explorerModalRef.current) {
            explorerModalRef.current.openModal();
            setIsVisible(false);
        }
    };

    const handleChangeBaseLine = useCallback(async () => {
        if (choosenBaseLinePlanning || currentBaseLinePlanning) {
            await updateTabPreferences({
                gantt_baseline: {
                    baseLinePlanning: choosenBaseLinePlanning || currentBaseLinePlanning,
                    showBaseLine: showBaseLineLayer,
                },
            });
            refreshPlanningSelected(showBaseLineLayer, showBaseLineLayer);
        } else {
            setIsVisible(false);
        }
    }, [showBaseLineLayer, choosenBaseLinePlanning]);

    const dropdownContent = isVisible ? (
        <DropdownContainer className="mt-3">
            <DropdownBody className="bg-white border border-secondary p-3">
                <PanelTitle className="mb-2">{t('baseline_planning')}</PanelTitle>
                <div className="flex mb-2 flex-col justify-center">
                    <div className="w-full mb-2">
                        <Checkbox
                            checked={showBaseLineLayer}
                            disabled={!currentBaseLinePlanning && !(selectValue || choosenBaseLinePlanning)}
                            onChange={(e) => setShowBaseLineLayer(e.target.checked)}
                        >
                            {t('show_baseline_planning')}
                        </Checkbox>
                    </div>
                    <label className="w-full mb-1 overflow-hidden">{t('current_planning')}:</label>
                    <p className="w-full truncate">{currentBaseLinePlanning?.name || t('no_choice')}</p>
                    <Divider className="my-2" />
                    <label className="w-full">{t('choose_archive')}:</label>
                    <Select className="w-full" onChange={handleArchiveSelection} value={selectValue}>
                            <Select.Option key='archive-null' value={null}/>
                                
                        {planningArchives.map((planning) => (
                            <Select.Option key={`archive-${planning.id}`} value={planning.id}>
                                {planning.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <label className="w-full my-2 text-center">{t('choice_or')}</label>
                    <Button type="secondary" className="font-bold" onClick={() => handleOpenExplorer()}>
                    {choosenBaseLinePlanning?.name || t('choose_specific_planning')}
                    </Button>
                    <div className="flex justify-end mt-2">
                        <Button
                            type="secondary mr-2"
                            onClick={() => {
                                setIsVisible(false);
                                setDefaultState();
                            }}
                        >
                            {generalTranslation('cancel')}
                        </Button>
                        <Button type="primary" onClick={() => handleChangeBaseLine()}>
                            {generalTranslation('apply')}
                        </Button>
                    </div>
                </div>
            </DropdownBody>
        </DropdownContainer>
    ) : (
        ''
    );

    return (
        <>
            <Dropdown
                overlay={loading ? <LoadingSpin /> : dropdownContent}
                trigger={['click']}
                open={isVisible}
                destroyPopupOnHide
                onOpenChange={(visible) => setIsVisible(visible)}
            >
                {/* <Spin spinning={loadingToolbar} size="large"> */}
                <Tooltip title={t('baseline_planning')}>
                    <QuickButton
                        type="button"
                        className={`ant-dropdown-link flex justify-center items-center  ${
                            isVisible ? 'bg-black text-white' : 'bg-primary'
                        }`}
                        tilte="Filtre"
                        onClick={(e) => e.preventDefault()}
                    >
                        <CompareSvg />
                    </QuickButton>
                </Tooltip>
                {/* </Spin> */}
            </Dropdown>
            <BaseLineExplorerModal
                ref={explorerModalRef}
                onPlanningChosen={(planning) => handlePlanningChosen(planning)}
            />
        </>
    );
};
export default BaseLineDropdown;
