import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import styled from 'styled-components';
import CustomFieldService from '../../services/custom-field.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { CustomFieldsMenuContext } from '../../contexts/custom-fields/CustomFieldsMenuContext';
import { requestError } from '../../helpers/notification';
import { ReactComponent as HatchLeftSvg } from '../../assets/hatch/left.svg';
import { ReactComponent as HatchRightSvg } from '../../assets/hatch/right.svg';
import { ReactComponent as HatchHorizontalSvg } from '../../assets/hatch/horizontal.svg';
import { ReactComponent as HatchVerticalSvg } from '../../assets/hatch/vertical.svg';
import { ReactComponent as PlainSvg } from '../../assets/hatch/plain.svg';
import { ALL_JOBS } from '../../constants/Jobs';
import CustomColorPicker from '../utils/CustomColorPicker';


const PartTitle = styled.h2`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const defaultColor = '#ffffffff';

const CustomFieldChoiceEditionForm = ({ customField, choiceName }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'modal_custom_fields' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { setModalContent, setShouldUpdatePlanning } = useContext(CustomFieldsMenuContext);
    const { refreshPlanningCustomFields, planningSelected } = useContext(PlanningContext);
    const choice = choiceName ? customField.type.choices[choiceName] : null;
    const [hachureValue, setHachureValue] = useState(choiceName ? choice.hachure ?? 'PLEIN' : 'PLEIN');
    const colorFields = {
        color: generalTranslation('principal'),
        trame: generalTranslation('secondary'),
    };
    const [entityColor, setEntityColor] = useState(
        Object.keys(colorFields).reduce(
            (agg, key) => ({
                ...agg,
                ...{
                    [key]: choice && key in choice ? choice[key] || defaultColor : defaultColor,
                },
            }),
            {}
        )
    );

    const createCustomFieldChoice = (values) => {
        setLoading(true);
        CustomFieldService.addChoice(
            customField.id,
            values.name === generalTranslation('all_jobs') ? ALL_JOBS : values.name,
            values.hachure === 'PLEIN' ? null : values.hachure,
            entityColor.color,
            values.hachure !== 'PLEIN' ? entityColor.trame : entityColor.color,
            planningSelected.rootActivityId
        )
            .then((result) => {
                setLoading(false);
                refreshPlanningCustomFields('edit', result);
                setShouldUpdatePlanning(true);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('error_add_choice'));
            });
    };

    const updateCustomField = (values) => {
        setLoading(true);
        CustomFieldService.updateChoice(
            customField.id,
            Object.assign(choice, { name: choiceName }),
            values.name === generalTranslation('all_jobs') ? ALL_JOBS : values.name,
            values.hachure === 'PLEIN' ? null : values.hachure,
            entityColor.color,
            values.hachure !== 'PLEIN' ? entityColor.trame : entityColor.color,
            planningSelected.rootActivityId
        )
            .then((result) => {
                setLoading(false);
                refreshPlanningCustomFields('edit', result);
                setShouldUpdatePlanning(true);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('error_edit_choice'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }

        if (choice) {
            updateCustomField(values);
        } else {
            createCustomFieldChoice(values);
        }
    };

    const updateEntityColor = (color, colorPickerKey) => {
        setEntityColor({
            ...entityColor,
            ...{ [colorPickerKey]: color },
        });
    };

    const entity = {
        name: (choiceName !== ALL_JOBS ? choiceName : generalTranslation('all_jobs')) || null,
        hachure: choiceName ? choice.hachure ?? 'PLEIN' : 'PLEIN',
    };

    const typeHachurage = [
        {
            value: 'HORIZONTAL',
            text: t('hatching_type_horizontal'),
            label: <HatchHorizontalSvg />,
        },
        {
            value: 'VERTICAL',
            text: t('hatching_type_vertical'),
            label: <HatchVerticalSvg />,
        },
        {
            value: 'BIAI',
            text: t('hatching_type_biai1'),
            label: <HatchLeftSvg />,
        },
        {
            value: 'BIAI1',
            text: t('hatching_type_biai2'),
            label: <HatchRightSvg />,
        },
        {
            value: 'PLEIN',
            text: t('hatching_type_full'),
            label: <PlainSvg />,
        },
    ];

    return (
        <Form
            form={form}
            layout="vertical"
            name="custom_field_choice_edit_form"
            initialValues={entity}
            onFinish={submitForm}
            className="h-full"
        >
            <Form.Item
                label={generalTranslation('name')}
                name="name"
                rules={[{ required: true, message: generalTranslation('required_field') }]}
            >
                <Input disabled={choiceName === ALL_JOBS} />
            </Form.Item>
            <Form.Item
                label={t('hatching_type')}
                name="hachure"
                rules={[{ required: true, message: generalTranslation('required_field') }]}
            >
                <Select
                    style={{ width: '250px', boxShadow: 'inset 2px 3px 3px rgba(130, 130, 130, 0.15)' }}
                    dropdownClassName="rounded"
                    onChange={(value) => setHachureValue(value)}
                >
                    {typeHachurage.map((type) => (
                        <Select.Option
                            key={`custom-field-${customField.id}-choice-select-type-hachurage${type.value}`}
                            value={type.value}
                        >
                            <div className="flex items-center">
                                <div style={{ width: '24px', height: '24px' }}>{type.label}</div>{' '}
                                <span className="ml-2 pr-2">{type.text}</span>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <PartTitle>{generalTranslation('colors')}</PartTitle>
            <div className="flex flex-wrap">
                {Object.keys(colorFields).map((colorPickerKey) => (
                    <Form.Item
                        key={`color-picker-${colorPickerKey}`}
                        label={colorFields[colorPickerKey]}
                        className={`mx-2 ${hachureValue === 'PLEIN' && colorPickerKey === 'trame' ? 'hidden' : ''}`}
                    >
                            <CustomColorPicker
                                color={entityColor[colorPickerKey] ?? defaultColor}
                                onChange={(color) => updateEntityColor(color, colorPickerKey)}
                            />
                    </Form.Item>
                ))}
            </div>
            <Row className="absolute bottom-0 right-0 m-5">
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={() => setModalContent(null)}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
CustomFieldChoiceEditionForm.propTypes = {
    customField: PropTypes.object.isRequired,
    choiceName: PropTypes.string,
};

CustomFieldChoiceEditionForm.defaultProps = {
    choiceName: null,
};

export default CustomFieldChoiceEditionForm;
