import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Empty, Row, Spin, Progress, Dropdown, Menu, Tabs, Typography, Modal, Layout, Badge, Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    PlusOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    EditOutlined,
    LinkOutlined,
    MenuOutlined,
    CaretLeftFilled,
    CaretRightFilled,
    ShareAltOutlined,
    WarningFilled,
} from '@ant-design/icons';
import { clone } from 'lodash';
import SplitPane from 'split-pane-react/esm/SplitPane';
import { Pane } from 'split-pane-react';
import LeftSidebar from '../components/layouts/app/LeftSidebar';
import TopMenu from '../components/layouts/app/TopMenu';
import Gantt from '../components/plannings/Gantt';
import { PlanningContext } from '../contexts/app/PlanningContext';
import { UserContext } from '../contexts/app/UserContext';
import { PrintTemplatesMenuContextProvider } from '../contexts/app/PrintTemplatesMenuContext';
// import SchedulerComponent from '../components/scheduler/Scheduler';
// import ResourcesCalendar from '../components/scheduler/ResourcesCalendar';
import AddNewViewModal from '../components/views/AddNewViewModal';
import { notificationError, notificationSuccess, requestError } from '../helpers/notification';
import SessionInfo from '../components/extra/SessionInfo';
import { TabContext } from '../contexts/app/TabContext';
import { TAB_TYPE } from '../constants/Tabs';
import { generateString } from '../helpers/string-helper';
import authService from '../services/auth.service';
import ShareViewModal from '../components/views/ShareViewModal';
import SharedViewModal from '../components/views/SharedViewModal';
import DraggableTabs from '../components/draggable-tabs/DraggableTabs';
// import { NeedChartContextProvider } from '../contexts/chart/ChartContext';
import GanttViewChartContainer from '../components/charts/GanttViewChartContainer';
import { RESOURCE_VIEW_MODE } from '../constants/Generic';
import { SliceContextProvider } from '../contexts/slicing/SliceContext';
import { NeedChartContextProvider } from '../contexts/chart/ChartContext';
import HistogramTabTopMenu from '../components/histogram-tab/HistogramTabTopMenu';
import HistogramTabChartContainer from '../components/histogram-tab/HistogramTabChartContainer';
import { saveScrollState } from '../components/plannings/gantt_events';
import SSEErrorModal from '../components/extra/SSEErrorModal';
import useMailTo from '../hooks/useMailTo.hook';

const { TabPane } = Tabs;
const { Text } = Typography;

const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const ActivitiesWrapperStyle = createGlobalStyle`
    .activities-wrapper {
        height: calc(100vh - 40px);
    }
    .activities-wrapper.single-tab {
        width: 98vw !important;
    }
`;
const TabContainer = styled.div`
    .ant-tabs-tab.ant-tabs-tab-active,
    .ant-tabs-tab-active .ant-typography,
    .ant-tabs-tab-active .ant-tabs-tab-remove,
    .ant-tabs-tab-active .ant-typography-edit {
        color: rgba(251, 190, 63, 1) !important;
        background: rgba(0, 0, 0, 1) !important;
    }
    .ant-tabs-tab {
        background: rgba(251, 190, 63, 1) !important;
        color: rgba(0, 0, 0, 1) !important;
    }
    .ant-typography-edit {
        display: flex !important;
        color: rgba(0, 0, 0, 1);
    }
    .ant-typography-edit-content {
        margin-bottom: 0px !important;
    }
    .ant-typography {
        display: flex;
        align-items: center;
    }
    .ant-typography-edit-content textarea {
        height: 24px;
        min-height: 24px;
        padding: 0 !important;
    }
    .ant-tabs-nav-add {
        padding: 0px !important;
        min-width: unset !important;
    }
    .ant-tabs-tab-remove {
        display: none !important;
    }
    .ant-tabs-tab .anticon {
        margin-right: 0px;
    }
`;

const MenuCollapseToogle = styled.button`
    height: 44px;
    width: 20px;
    position: absolute;
    top: 0px;
    left: -20px;
    border-radius: 4px;
`;

const BottomInfo = styled.div`
    position: absolute;
    bottom: 0px;
    margin-left: 20px;
    left: 0px;
`;
// const AdminInfo = styled.div`
//     width: 217px;
//     background: #ff4d4f;
//     margin-right: 20px;
//     border-radius: 2px;
// `;
const TopInfo = styled.div`
    width: 217px;
    position: absolute;
    top: -20px;
    left: 0px;
    background: #ff4d4f;
    border-radius: 2px;
    line-height: 20px;
`;

const SIDER_DEFAULT_WIDTH = 217;
const MAX_TIME_LOAD = 30000; // 30 seconds
let panelSizes = [SIDER_DEFAULT_WIDTH, 'auto'];

const Planning = (componentProps) => {
    const { t } = useTranslation('translation', { keyPrefix: 'planning_tabs' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const sseTranslation = useTranslation('translation', { keyPrefix: 'sse_test' }).t;
    const { i18n } = useTranslation();
    const { planningSelected, spinProgress, loadingGantt, processingGantt, folderSelected } =
        useContext(PlanningContext);
    const {
        planningTabs,
        activeTab,
        tabPreferences,
        setActiveTab,
        setTabPreferences,
        refreshPlanningTabs,
        setRefetchTabs,
        sharedTabs,
        updateTabPreferences,
    } = useContext(TabContext);

    const { sendLatencyProblemMail } = useMailTo();

    const [collapsed, setCollapsed] = useState(false);
    const [addingNewView, setAddingNewView] = useState(false);
    const [layoutSplitPanelSizes, setLayoutSplitPanelSizes] = useState([SIDER_DEFAULT_WIDTH, 'auto']);
    const [ganttSplitPanelSizes, setGanttSplitPanelSizes] = useState(['auto', 'auto']);
    const { userPreferences, currentUser } = useContext(UserContext);
    const programName = JSON.parse(localStorage.getItem('prg'))?.name;

    const indicator = <Progress percent={spinProgress} steps={5} showInfo={false} strokeColor="#fbbe3f" />;
    const [currentViews, setCurrentViews] = useState([]);
    const [shownViewIds, setShownViewIds] = useState([]);
    const [planningViews, setPlanningViews] = useState([]);
    const [editViewKey, setEditViewKey] = useState(null);
    const [isSingleTab, setIsSingleTab] = useState(false);

    // const [initialTimeLoad, setInitialTimeLoad] = useState(Date.now());
    const [isLoadTimeExceeded, setIsLoadTimeExcedeed] = useState(false);
    const [showTimeExcedeedModal, setShowTimeExcedeedModal] = useState(false);

    const initialTimeLoad = useRef();
    const isLoadTimeExceededRef = useRef();

    const AddViewModalRef = useRef();
    const ShareViewModalRef = useRef();
    const SharedViewModalRef = useRef();

    const SSEErrorModalRef = useRef();
    const [SSEStatusError, setSSEStatusError] = useState();
    useEffect(() => {
        console.log('changing tabs', planningTabs);
        setPlanningViews(clone(planningTabs));
        setShownViewIds(clone(planningTabs.filter((tab) => tab.display).map((tab) => tab.id)));
    }, [planningTabs]);

    useEffect(() => {
        isLoadTimeExceededRef.current = isLoadTimeExceeded;
    }, [isLoadTimeExceeded]);

    const checkPlanningLoad = () => {
        if (
            initialTimeLoad.current &&
            Date.now() - initialTimeLoad.current > MAX_TIME_LOAD &&
            isLoadTimeExceededRef.current === false
        ) {
            console.log('SHOW MODAL');
            setIsLoadTimeExcedeed(true);
            setShowTimeExcedeedModal(true);
        }
    };

    useEffect(() => {
        const timer = setInterval(checkPlanningLoad, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (loadingGantt) {
            initialTimeLoad.current = Date.now();
            setIsLoadTimeExcedeed(false);
        } else {
            setShowTimeExcedeedModal(false);
            initialTimeLoad.current = undefined;
        }
    }, [loadingGantt]);

    useEffect(() => {
        if (planningViews.length > 0 && shownViewIds) {
            const currentViewList = [];
            shownViewIds.forEach((id) => {
                const viewObj = planningViews.find((i) => i.id === id);
                if (viewObj) {
                    currentViewList.push(viewObj);
                }
            });
            if (shownViewIds.length === 1) {
                setActiveTab(currentViewList[0]);
            }
            // eslint-disable-next-line
            if (componentProps?.match?.params.viewId) {
                setIsSingleTab(true);
                setLayoutSplitPanelSizes([0, 'auto']);
                // eslint-disable-next-line
                const selectedView = currentViewList.find((i) => i.id === Number(componentProps?.match?.params.viewId));
                if (selectedView) {
                    setActiveTab(selectedView);
                    setCurrentViews([selectedView]);
                } else {
                    notificationError('Séléction de la vue', "La vue souhaitée n'existe pas");
                }
            } else {
                setCurrentViews(currentViewList);
            }
            // if (currentViewList.length === 1) {
            //     setActiveView(currentViewList[0]);
            // }
        } else {
            // no views
            setCurrentViews([]);
            // setActiveView(null);
        }
    }, [planningViews, shownViewIds]);

    const handleNewTabOrder = (newOrder) => {
        const tabUpdatePromises = newOrder.map((id, index) => authService.updatePlanningTab(id, { order: index }));
        Promise.all(tabUpdatePromises).then(() => setRefetchTabs((val) => val + 1));
        setShownViewIds(newOrder.map((i) => Number(i)));
    };

    const handleAddView = async (view) => {
        const updatedTab = await authService.updatePlanningTab(view.id, { display: true });
        refreshPlanningTabs('edit', updatedTab);
        // setShownViewIds((old) => [...old, view.id]);
    };

    const handleAddNewView = async (viewObject) => {
        try {
            setAddingNewView(true);
            await authService.createPlanningTab(planningSelected.id, viewObject.type, viewObject.name);
            // refreshPlanningTabs('new', newTab);
            setRefetchTabs((val) => val + 1);
            setAddingNewView(false);
        } catch (error) {
            requestError(error, t('new_view'));
        }
    };
    const handleShareView = async ({ teamId }) => {
        try {
            await authService.sharePlanningTab(activeTab.id, teamId);
            notificationSuccess(t('share_view'), t('share_view_success'));
        } catch (error) {
            requestError(error, t('share_view'));
        }
    };

    const TabsMenu = [
        {
            label: t('edit_view_name'),
            key: 'edit-view-name',
            icon: <EditOutlined />,
        },
        {
            label: t('open_view_standalone'),
            key: 'open-view-outside',
            icon: <LinkOutlined />,
        },
        {
            label: t('duplicate_view'),
            key: 'duplicate-view',
            icon: <CopyOutlined />,
        },
        {
            label: t('hide_view'),
            key: 'hide-view',
            icon: <EyeInvisibleOutlined />,
        },
        {
            label: generalTranslation('share'),
            key: 'share-view',
            icon: <ShareAltOutlined />,
        },
        {
            label: t('delete_view'),
            key: 'delete-view',
            icon: <DeleteOutlined />,
        },
    ];

    const viewsChoiceRender = (
        <Menu>
            {planningViews
                .filter((i) => shownViewIds.indexOf(i.id) === -1)
                .map((view) => (
                    <Menu.Item key={`viewChoice${view.id}`} onClick={() => handleAddView(view)} icon={<EyeOutlined />}>
                        {view.name}
                    </Menu.Item>
                ))}
            <Menu.Item key="viewChoice-new" icon={<PlusOutlined />} onClick={() => AddViewModalRef.current.openModal()}>
                <Text italic strong>
                    {t('add_new_view')}
                </Text>
            </Menu.Item>
            {sharedTabs.length > 0 && (
                <Menu.Item
                    key="viewChoice-shared"
                    icon={<ShareAltOutlined />}
                    onClick={() => SharedViewModalRef.current.openModal()}
                >
                    <Badge dot>
                        <Text strong={sharedTabs.length > 0}>{t('shared_views')}</Text>
                    </Badge>
                </Menu.Item>
            )}
        </Menu>
    );

    const handleChangeViewName = async (val, viewKey) => {
        // const newViews = planningViews;
        // const viewIndex = planningViews.findIndex((i) => i.id === Number(viewKey));
        // newViews[viewIndex].name = val;
        const updatedView = await authService.updatePlanningTab(viewKey, { name: val });
        refreshPlanningTabs('edit', updatedView);
        // setPlanningViews(JSON.parse(JSON.stringify(newViews)));
        setEditViewKey(null);
    };
    const searchNextActiveTab = useCallback(
        (viewKey) => {
            setTabPreferences(null);
            const indexInShown = shownViewIds.indexOf(viewKey);
            const shownViews = JSON.parse(JSON.stringify(shownViewIds));
            shownViews.splice(indexInShown, 1);
            setShownViewIds([...shownViews]);
            if (viewKey === activeTab?.id && shownViews.length > 0) {
                setActiveTab({
                    ...planningViews.find((i) => i.id === shownViews[indexInShown > 0 ? indexInShown - 1 : 0]),
                });
            }
            if (shownViews.length === 0) {
                setActiveTab(null);
            }
        },
        [shownViewIds, planningViews]
    );
    const handleRemoveView = async (viewKey) => {
        await authService.deletePlanningTab(viewKey);
        refreshPlanningTabs('delete', { id: viewKey });
        searchNextActiveTab(viewKey);
        if (window.extraViews && window.extraViews.length > 0) {
            const extraView = window.extraViews.find((v) => v.viewId === viewKey);
            if (extraView) {
                extraView.viewInstance.close();
            }
        }
    };

    const viewRender = () => {
        const view = activeTab;
        let render = '';

        switch (view.tabType) {
            case TAB_TYPE.GANTT: {
                const showHistogram =
                    tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value;
                render = (
                    <div className="flex-grow flex flex-col" style={{ height: '100px' }}>
                        <SplitPane
                            split="horizontal"
                            sizes={showHistogram ? ganttSplitPanelSizes : ['100%', 0]}
                            allowResize={showHistogram}
                            onChange={(sizes) => (showHistogram ? setGanttSplitPanelSizes(sizes) : undefined)}
                        >
                            <Pane>
                                <div style={layoutCSS}>
                                    <div className="h-full w-full flex-grow flex flex-col">
                                        <TopMenu />
                                        <Gantt />
                                    </div>
                                </div>
                            </Pane>
                            <Pane>
                                <SliceContextProvider>
                                    {showHistogram && (
                                        <div style={layoutCSS}>
                                            <NeedChartContextProvider>
                                                <GanttViewChartContainer />
                                            </NeedChartContextProvider>
                                        </div>
                                    )}
                                </SliceContextProvider>
                            </Pane>
                        </SplitPane>
                    </div>
                );
                break;
            }
            case TAB_TYPE.RESOURCE:
                render = (
                    <SliceContextProvider>
                        <div className="flex-grow flex flex-col" style={{ height: '100px' }}>
                            <NeedChartContextProvider>
                                <HistogramTabTopMenu />
                                <HistogramTabChartContainer />
                            </NeedChartContextProvider>
                        </div>
                    </SliceContextProvider>
                );
                break;
            // case TAB_TYPE.NEEDS_HISTOGRAM:
            //     render = (
            //         <div>
            //             Besoins du planning
            //         </div>
            //     );
            //     break;
            // case TAB_TYPE.AGENDA:
            //     render = (
            //         <div className="flex flex-grow flex-col">
            //             <SchedulerComponent />
            //         </div>
            //     );
            //     break;
            // case TAB_TYPE.CALENDAR:
            //     render = (
            //         <div>
            //             <ResourcesCalendar />
            //         </div>
            //     );
            //     break;

            default:
                render = <Empty description="Pas de vue correspondante" />;
                break;
        }
        return render;
    };

    const handleContextMenuClick = async (action, viewObject) => {
        switch (action) {
            case 'edit-view-name':
                setEditViewKey(viewObject.id);
                break;

            case 'open-view-outside': {
                const viewInstance = window.open(
                    `${window.location.protocol}//${window.location.host}/planning/${viewObject.id}`,
                    viewObject.label,
                    'location=1,status=1,scrollbars=1'
                );
                if (window.extraViews) {
                    window.extraViews.push({ viewId: viewObject.id, viewInstance });
                } else {
                    window.extraViews = [];
                    window.extraViews.push({ viewId: viewObject.id, viewInstance });
                }
                break;
            }

            case 'duplicate-view': {
                await authService.duplicatePlanningTab(viewObject.id, `${viewObject.name}-Copie`);
                setRefetchTabs((val) => val + 1);
                break;
            }
            case 'hide-view': {
                const updatedTab = await authService.updatePlanningTab(viewObject.id, { display: false });
                console.log('🚀 ~ file: Planning.jsx:483 ~ handleContextMenuClick ~ updatedTab', updatedTab);
                refreshPlanningTabs('edit', updatedTab);
                searchNextActiveTab(viewObject.id);
                break;
            }
            case 'delete-view': {
                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: t('confirm_delete_view'),

                    onOk() {
                        handleRemoveView(viewObject.id);
                    },

                    onCancel() {
                        Modal.destroyAll();
                    },
                });
                break;
            }
            case 'share-view': {
                ShareViewModalRef.current.openModal();
                break;
            }

            default:
                break;
        }
    };
    const handleChangeView = async (activeKey) => {
        await saveScrollState(updateTabPreferences);
        setTabPreferences(null);
        const newActiveView = currentViews.find((i) => i.id === Number(activeKey));
        console.log('changing', newActiveView);
        setActiveTab(newActiveView);
    };

    const resizeCallback = (sizes) => {
        panelSizes = sizes;
        setLayoutSplitPanelSizes(sizes);
        // const siderContainerUpdated = document.getElementById('sider-container');
        // setSiderWidth(siderContainerUpdated.clientWidth)
    };

    const handleCollapseSider = () =>
        setCollapsed((old) => {
            const isCollapsed = !old === true;
            if (isCollapsed) {
                setLayoutSplitPanelSizes([0, 'auto']);
            } else {
                setLayoutSplitPanelSizes(panelSizes);
                // setTimeout(() => {
                //     resizeCallback();
                // }, 500);
                // setSplitPaneKey(generateString(8));
            }
            return !old;
        });

    return (
        <DndProvider backend={HTML5Backend}>
            <PrintTemplatesMenuContextProvider>
                <ActivitiesWrapperStyle />
                <Layout className="page-wrapper bg-white h-screen">
                    <SplitPane sizes={layoutSplitPanelSizes} onChange={(sizes) => resizeCallback(sizes)}>
                        <Pane minSize={SIDER_DEFAULT_WIDTH}>
                            <div style={{ layoutCSS }} className="relative ">
                                {currentUser.isAdmin && !currentUser.readOnly && (
                                    <TopInfo className="text-center text-white">{t('admin_mode')}</TopInfo>
                                )}
                                {currentUser.readOnly && (
                                    <TopInfo className="text-center text-white">{t('read_only_mode')}</TopInfo>
                                )}
                                {!isSingleTab && (
                                    <>
                                        <MenuCollapseToogle
                                            className="bg-transparent hover:bg-primary"
                                            title={`${collapsed ? t('show_menu') : t('hide_menu')}`}
                                            onClick={() => handleCollapseSider()}
                                        >
                                            {collapsed ? <CaretRightFilled /> : <CaretLeftFilled />}
                                            <MenuOutlined />
                                        </MenuCollapseToogle>
                                        <div className="bg-transparent w-full page-sider">
                                            <LeftSidebar />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Pane>
                        <div style={{ layoutCSS }}>
                            <Layout>
                                <Row className="flex flex-grow flex-nowrap">
                                    <Spin
                                        spinning={!userPreferences || loadingGantt || processingGantt}
                                        size="large"
                                        wrapperClassName="ant-spin-full w-full"
                                        tip={indicator}
                                    >
                                        <Col
                                            className={`flex-grow flex flex-col overflow-x-visible activities-wrapper ${
                                                planningSelected === null ? 'justify-center' : ''
                                            }`}
                                            style={{ paddingLeft: `${collapsed || isSingleTab ? 0 : 20}px` }}
                                        >
                                            {planningSelected && !loadingGantt && (
                                                <>
                                                    <TabContainer>
                                                        <DraggableTabs
                                                            type="editable-card"
                                                            hideAdd={isSingleTab}
                                                            size="small"
                                                            onNewOrder={(order) => handleNewTabOrder(order)}
                                                            addIcon={
                                                                <Dropdown
                                                                    key={generateString(6)}
                                                                    overlay={viewsChoiceRender}
                                                                    trigger={['click']}
                                                                    size="small"
                                                                    placement="bottomLeft"
                                                                >
                                                                    <Badge
                                                                        count={sharedTabs.length}
                                                                        size="small"
                                                                        offset={[0, 7]}
                                                                    >
                                                                        <PlusOutlined
                                                                            spin={addingNewView}
                                                                            style={{ padding: '10px' }}
                                                                        />
                                                                    </Badge>
                                                                </Dropdown>
                                                            }
                                                            activeKey={activeTab?.id.toString()}
                                                            onChange={(key) => handleChangeView(key)}
                                                        >
                                                            {currentViews.map((view) => (
                                                                <TabPane
                                                                    tab={
                                                                        <div className="flex items-center justify-between">
                                                                            {+activeTab?.id === +view?.id ? (
                                                                                <Dropdown
                                                                                    key={generateString(6)}
                                                                                    overlay={
                                                                                        <Menu
                                                                                            onClick={({ key }) =>
                                                                                                handleContextMenuClick(
                                                                                                    key,
                                                                                                    view
                                                                                                )
                                                                                            }
                                                                                            items={TabsMenu}
                                                                                        />
                                                                                    }
                                                                                    trigger={['click']}
                                                                                >
                                                                                    <div className="flex items-center justify-between">
                                                                                        <Text
                                                                                            key={`editor${view?.id}`}
                                                                                            type="primary"
                                                                                            onKeyDown={(e) =>
                                                                                                e.stopPropagation()
                                                                                            }
                                                                                            className="mr-2"
                                                                                            editable={{
                                                                                                editing:
                                                                                                    editViewKey ===
                                                                                                    view?.id,
                                                                                                tooltip: false,
                                                                                                triggerType: ['text'],
                                                                                                enterIcon: null,
                                                                                                onKeyDown: (e) =>
                                                                                                    e.stopPropagation(),
                                                                                                onChange: (e) =>
                                                                                                    handleChangeViewName(
                                                                                                        e,
                                                                                                        view?.id
                                                                                                    ),
                                                                                            }}
                                                                                        >
                                                                                            {view.name}
                                                                                        </Text>
                                                                                        <DownOutlined />
                                                                                    </div>
                                                                                </Dropdown>
                                                                            ) : (
                                                                                <Text
                                                                                    key={`editor${view?.id}`}
                                                                                    type="primary"
                                                                                    onKeyDown={(e) =>
                                                                                        e.stopPropagation()
                                                                                    }
                                                                                    className="mr-2"
                                                                                    editable={{
                                                                                        editing:
                                                                                            editViewKey === view?.id,
                                                                                        tooltip: false,
                                                                                        triggerType: ['text'],
                                                                                        enterIcon: null,
                                                                                        onKeyDown: (e) =>
                                                                                            e.stopPropagation(),
                                                                                        onChange: (e) =>
                                                                                            handleChangeViewName(
                                                                                                e,
                                                                                                view?.id
                                                                                            ),
                                                                                    }}
                                                                                >
                                                                                    {view.name}
                                                                                </Text>
                                                                            )}
                                                                        </div>
                                                                    }
                                                                    key={view?.id?.toString()}
                                                                />
                                                            ))}
                                                        </DraggableTabs>
                                                    </TabContainer>
                                                    {activeTab && tabPreferences && viewRender()}
                                                </>
                                            )}
                                            {planningSelected && !activeTab && !loadingGantt && (
                                                <div className="flex-grow ">
                                                    <div className="h-full w-full flex justify-center items-center">
                                                        <Empty description={t('no_active_view')} />
                                                    </div>
                                                </div>
                                            )}
                                            {!planningSelected && !loadingGantt && (
                                                <div className="flex justify-center">
                                                    <Empty description={t('no_planning_selected')} />
                                                    {!userPreferences || loadingGantt || processingGantt}
                                                </div>
                                            )}
                                        </Col>
                                    </Spin>
                                </Row>
                            </Layout>
                        </div>
                    </SplitPane>
                    <BottomInfo className="ml-2 flex">
                        {SSEStatusError && (
                            <label className="mr-2">
                                <Button
                                    type="danger"
                                    className="flex items-center"
                                    size="small"
                                    onClick={() => SSEErrorModalRef.current.openModal()}
                                >
                                    <WarningFilled className="mr-1 animate-ping" />
                                    {sseTranslation('connexion_problem')}
                                    <WarningFilled className="ml-1 animate-ping" />
                                </Button>
                            </label>
                        )}
                        <div>
                            <SessionInfo />
                            {(collapsed || isSingleTab) && (
                                <>
                                    <label>{t('program')}: </label>
                                    <span>{programName}</span> &nbsp; &nbsp;
                                    <label>{t('folder')}: </label>
                                    <span>{folderSelected?.name}</span> &nbsp; &nbsp;
                                    <label>{t('planning')}: </label>
                                    <span>{planningSelected?.name}</span>
                                </>
                            )}
                        </div>
                    </BottomInfo>
                </Layout>
                <Modal
                    open={isLoadTimeExceeded && showTimeExcedeedModal}
                    onCancel={() => setShowTimeExcedeedModal(false)}
                    footer={null}
                >
                    <Row>{t('planning_loading_warning')}</Row>
                    <Row className="mt-2 flex items-center justify-center">
                        <Button
                            type="primary"
                            className="mr-2"
                            onClick={() => {
                                document.getElementById('explorer-modal')?.click();
                                setShowTimeExcedeedModal(false);
                            }}
                        >
                            {t('planning_loading_explorer')}
                        </Button>
                        <Button className="mr-2" type="primary" onClick={() => sendLatencyProblemMail()}>
                            {t('contact_support_team')}
                        </Button>

                        <Button onClick={() => window.location.reload()}> {t('planning_loading_reload')}</Button>
                    </Row>
                </Modal>

                <SSEErrorModal ref={SSEErrorModalRef} onError={(error) => setSSEStatusError(error)} />

                <AddNewViewModal onOk={handleAddNewView} ref={AddViewModalRef} />
                <SharedViewModal ref={SharedViewModalRef} />
                {activeTab && <ShareViewModal activeView={activeTab} onOk={handleShareView} ref={ShareViewModalRef} />}
            </PrintTemplatesMenuContextProvider>
        </DndProvider>
    );
};

export default Planning;
