import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Empty, Popover } from 'antd';
import styled from 'styled-components';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import { ReactComponent as TexteSvg } from '../../assets/icons/texte.svg';
import { ReactComponent as PhotoSvg } from '../../assets/icons/photo.svg';
import { ReactComponent as FolderSvg } from '../../assets/icons/white-folder.svg';
import { ReactComponent as CalendarSvg } from '../../assets/icons/calendrier.svg';
import { ReactComponent as NoteSvg } from '../../assets/icons/note.svg';
import { PrintTemplatesMenuContext } from '../../contexts/app/PrintTemplatesMenuContext';
import PrintTemplateEditionElementsForm from './form/PrintTemplateEditionElementsForm';
import { requestError } from '../../helpers/notification';
import PrintTemplateService from '../../services/print-template.service';
import AuthService from '../../services/auth.service';
import CustomColorPicker from '../utils/CustomColorPicker';


const PanelWrapper = styled.div`
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    height: calc(100% - 40px);
`;

const PanelTitle = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #fbbe3f;
`;

const EditButton = styled.button`
    width: auto;
    height: auto;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    background: #fefefe;
    box-shadow: inset -1px -1px 3px rgba(130, 130, 130, 0.27);
    border-radius: 2px;

    &:hover {
        background: #fbbe3f;
    }
    &:disabled {
        cursor: not-allowed;
    }
`;

const StyledButton = styled(Button)`
    height: auto;
`;

const PopoverContent = styled.div`
    margin: -12px -50px;
    background-color: #fbbe3f;
    color: white;
`;

const EmptyTemplate = styled(Empty)`
    width: calc(100% - 210px);
    padding-top: 130px;
`;

const PrintTemplatesList = () => {
    const { setSelectedPrintTemplate, selectedPrintTemplate, setModalContent, refreshPrintTemplate } =
        useContext(PrintTemplatesMenuContext);
    const [selectedPlacement, setSelectedPlacement] = useState();
    // colors
    const [headerColor, setHeaderColor] = useState('#FFFFFF');
    const [footerColor, setFooterColor] = useState('#FFFFFF');
    const { isAdmin } = AuthService.getCurrentUser();

    const { t } = useTranslation();

    const canEdit = useMemo(
        () => selectedPrintTemplate?.personal || (isAdmin && !selectedPrintTemplate?.personal),
        [selectedPrintTemplate, isAdmin]
    );

    const editPrintTemplateElements = (printTemplate, type, placement) => {
        setModalContent(
            <PrintTemplateEditionElementsForm printTemplate={printTemplate} type={type} placement={placement} />
        );
    };

    const deleteTemplateElement = (printTemplate) => {
        let placement;
        setSelectedPlacement((val) => {
            placement = val;
            return val;
        });

        PrintTemplateService.removeTemplateElement(printTemplate.id, placement)
            .then((updatedPrintTemplate) => {
                setSelectedPrintTemplate(updatedPrintTemplate);
                refreshPrintTemplate('edit', updatedPrintTemplate);
            })
            .catch((error) => {
                requestError(error, t('error_message.error_when_deleting_the_print_template'));
            });
    };

    useEffect(() => {
        if (selectedPrintTemplate) {
            // apply color
            if (selectedPrintTemplate.color) {
                setHeaderColor(selectedPrintTemplate.color.header);
                setFooterColor(selectedPrintTemplate.color.footer);
            } else {
                setHeaderColor('#FFFFFF');
                setFooterColor('#FFFFFF');
            }
        }
    }, [selectedPrintTemplate]);

    const contentEdit = (
        <PopoverContent>
            <ul className="flex">
                <li className="flex flex-col items-center px-2 py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'TEXT', selectedPlacement)}
                    >
                        <TexteSvg className="mx-auto" />
                        <p className="text-white">{t('print.text')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'IMAGE', selectedPlacement)}
                    >
                        <PhotoSvg className="mx-auto" />
                        <p className="text-white">{t('print.image')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() =>
                            editPrintTemplateElements(selectedPrintTemplate, 'PROJECT_NAME', selectedPlacement)
                        }
                    >
                        <FolderSvg className="mx-auto" />
                        <p className="text-white">{t('print.project_name')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'DATE', selectedPlacement)}
                    >
                        <CalendarSvg className="mx-auto" />
                        <p className="text-white">{t('print.date')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center px-2 py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'PAGE', selectedPlacement)}
                    >
                        <NoteSvg className="mx-auto" />
                        <p className="text-white">{t('print.page_no')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center px-2 py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => deleteTemplateElement(selectedPrintTemplate, 'PAGE', selectedPlacement)}
                        style={{ color: 'white' }}
                    >
                        <CloseSvg className="mx-auto" />
                        <p className="text-white">{t('print.empty')}</p>
                    </StyledButton>
                </li>
            </ul>
        </PopoverContent>
    );
    const contentAdd = (
        <PopoverContent>
            <ul className="flex">
                <li className="flex flex-col items-center px-2 py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'TEXT', selectedPlacement)}
                    >
                        <TexteSvg className="mx-auto" />
                        <p className="text-white">{t('print.text')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'IMAGE', selectedPlacement)}
                    >
                        <PhotoSvg className="mx-auto" />
                        <p className="text-white">{t('print.image')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() =>
                            editPrintTemplateElements(selectedPrintTemplate, 'PROJECT_NAME', selectedPlacement)
                        }
                    >
                        <FolderSvg className="mx-auto" />
                        <p className="text-white">{t('print.project_name')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'DATE', selectedPlacement)}
                    >
                        <CalendarSvg className="mx-auto" />
                        <p className="text-white">{t('print.date')}</p>
                    </StyledButton>
                </li>
                <li className="flex flex-col items-center px-2 py-5 text-center">
                    <StyledButton
                        type="link"
                        onClick={() => editPrintTemplateElements(selectedPrintTemplate, 'PAGE', selectedPlacement)}
                    >
                        <NoteSvg className="mx-auto" />
                        <p className="text-white">{t('print.page_no')}</p>
                    </StyledButton>
                </li>
            </ul>
        </PopoverContent>
    );

    const getValue = (value, type) => {
        switch (type) {
            case 'IMAGE':
                return <img src={value} alt="" style={{ maxWidth: 90 }} />;
            case 'DATE':
                return t('print.date');
            case 'PAGE':
                return t('print.page_no');
            case 'PROJECT_NAME':
                return t('print.project_name');
            default:
                return value;
        }
    };

    const updateTemplateColors = (header, footer) => {
        PrintTemplateService.updateTemplateColors(selectedPrintTemplate.id, {
            headerColor: header,
            footerColor: footer,
        })
            .then((updatedPrintTemplate) => {
                setSelectedPrintTemplate(updatedPrintTemplate);
                refreshPrintTemplate('edit', updatedPrintTemplate);
            })
            .catch((error) => {
                requestError(error, t('error_message.error_when_modifying_the_print_template'));
            });
    };

    return (
        <>
            {selectedPrintTemplate ? (
                <Col flex="427px" className="h-full">
                    <div className="flex justify-between items-center" style={{ marginBottom: 12 }}>
                        <div>
                            <PanelTitle>
                                {t('print.template')}: {selectedPrintTemplate.name}
                            </PanelTitle>
                        </div>
                    </div>
                    <PanelWrapper className="flex flex-col" style={{ height: 352 }}>
                        <div className="flex justify-between items-center w-full pb-1">
                            <p className="font-bold">{t('print.header')}</p>
                            <div>
                                <CustomColorPicker
                                    disabled={!canEdit}
                                    color={headerColor ?? '#FFFFFF'}
                                    onChange={(color) => {
                                        setHeaderColor(color);
                                        updateTemplateColors(color, footerColor);
                                    }}
                                />
                            </div>
                        </div>
                        <ul className="flex rounded p-2" style={{ background: headerColor }}>
                            <li className="flex-1 text-left">
                                <Popover
                                    placement="bottomLeft"
                                    content={selectedPrintTemplate.headerLeft ? contentEdit : contentAdd}
                                    trigger="click"
                                >
                                    <EditButton disabled={!canEdit} onClick={() => setSelectedPlacement('HEADER_LEFT')}>
                                        {selectedPrintTemplate.headerLeft ? (
                                            <div className="flex mx-1">
                                                <p className="mr-2">
                                                    {getValue(
                                                        selectedPrintTemplate.headerLeft.value,
                                                        selectedPrintTemplate.headerLeft.type
                                                    )}
                                                </p>
                                                <EditSvg />
                                            </div>
                                        ) : (
                                            '+'
                                        )}
                                    </EditButton>
                                </Popover>
                            </li>
                            <li className="flex-1 text-center">
                                <Popover
                                    placement="bottom"
                                    content={selectedPrintTemplate.headerCenter ? contentEdit : contentAdd}
                                    trigger="click"
                                >
                                    <EditButton
                                        disabled={!canEdit}
                                        onClick={() => setSelectedPlacement('HEADER_CENTER')}
                                    >
                                        {selectedPrintTemplate.headerCenter ? (
                                            <div className="flex mx-1">
                                                <p className="mr-2">
                                                    {getValue(
                                                        selectedPrintTemplate.headerCenter.value,
                                                        selectedPrintTemplate.headerCenter.type
                                                    )}
                                                </p>
                                                <EditSvg />
                                            </div>
                                        ) : (
                                            '+'
                                        )}
                                    </EditButton>
                                </Popover>
                            </li>
                            <li className="flex-1 text-right">
                                <Popover
                                    placement="bottomRight"
                                    content={selectedPrintTemplate.headerRight ? contentEdit : contentAdd}
                                    trigger="click"
                                >
                                    <EditButton
                                        disabled={!canEdit}
                                        onClick={() => setSelectedPlacement('HEADER_RIGHT')}
                                    >
                                        {selectedPrintTemplate.headerRight ? (
                                            <div className="flex mx-1">
                                                <p className="mr-2">
                                                    {getValue(
                                                        selectedPrintTemplate.headerRight.value,
                                                        selectedPrintTemplate.headerRight.type
                                                    )}
                                                </p>
                                                <EditSvg />
                                            </div>
                                        ) : (
                                            '+'
                                        )}
                                    </EditButton>
                                </Popover>
                            </li>
                        </ul>
                        <div style={{ maxHeight: 216, height: '100%' }} />
                        <div className="flex justify-between items-center w-full pb-1">
                            <p className="font-bold">{t('print.footer')}</p>
                            <div>
                                <CustomColorPicker
                                    disabled={!canEdit}
                                    color={footerColor ?? '#FFFFFF'}
                                    onChange={(color) => {
                                        setHeaderColor(color);
                                        updateTemplateColors(headerColor, color);
                                    }}
                                />
                            </div>
                        </div>
                        <ul className="flex rounded p-2" style={{ background: footerColor }}>
                            <li className="flex-1 text-left ">
                                <Popover
                                    placement="topLeft"
                                    content={selectedPrintTemplate.footerLeft ? contentEdit : contentAdd}
                                    trigger="click"
                                >
                                    <EditButton disabled={!canEdit} onClick={() => setSelectedPlacement('FOOTER_LEFT')}>
                                        {selectedPrintTemplate.footerLeft ? (
                                            <div className="flex mx-1">
                                                <p className="mr-2">
                                                    {getValue(
                                                        selectedPrintTemplate.footerLeft.value,
                                                        selectedPrintTemplate.footerLeft.type
                                                    )}
                                                </p>
                                                <EditSvg />
                                            </div>
                                        ) : (
                                            '+'
                                        )}
                                    </EditButton>
                                </Popover>
                            </li>
                            <li className="flex-1 text-center">
                                <Popover
                                    placement="top"
                                    content={selectedPrintTemplate.footerCenter ? contentEdit : contentAdd}
                                    trigger="click"
                                >
                                    <EditButton
                                        disabled={!canEdit}
                                        onClick={() => setSelectedPlacement('FOOTER_CENTER')}
                                    >
                                        {selectedPrintTemplate.footerCenter ? (
                                            <div className="flex mx-1">
                                                <p className="mr-2">
                                                    {getValue(
                                                        selectedPrintTemplate.footerCenter.value,
                                                        selectedPrintTemplate.footerCenter.type
                                                    )}
                                                </p>
                                                <EditSvg />
                                            </div>
                                        ) : (
                                            '+'
                                        )}
                                    </EditButton>
                                </Popover>
                            </li>
                            <li className="flex-1 text-right">
                                <Popover
                                    placement="topRight"
                                    content={selectedPrintTemplate.footerRight ? contentEdit : contentAdd}
                                    trigger="click"
                                >
                                    <EditButton
                                        disabled={!canEdit}
                                        onClick={() => setSelectedPlacement('FOOTER_RIGHT')}
                                    >
                                        {selectedPrintTemplate.footerRight ? (
                                            <div className="flex mx-1">
                                                <p className="mr-2">
                                                    {getValue(
                                                        selectedPrintTemplate.footerRight.value,
                                                        selectedPrintTemplate.footerRight.type
                                                    )}
                                                </p>
                                                <EditSvg />
                                            </div>
                                        ) : (
                                            '+'
                                        )}
                                    </EditButton>
                                </Popover>
                            </li>
                        </ul>
                    </PanelWrapper>
                </Col>
            ) : (
                <EmptyTemplate description={t('print.no_template_is_selected')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </>
    );
};

export default PrintTemplatesList;
