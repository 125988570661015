import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ActivitySlice } from 'slicing-gm';
import { TabContext } from '../app/TabContext';
import { PlanningContext } from '../app/PlanningContext';
import GanttConfigurations from '../../components/plannings/gantt_config';
import { formatResourceTask } from '../../helpers/planning';
import { computeActivityCalendarSlice, timer } from '../../helpers/slicing-helper';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import { xAxisDateFormat } from '../../components/charts/CustomHistogramDateXaxis';
import { TAB_TYPE } from '../../constants/Tabs';
import { restoreScrollState } from '../../components/plannings/gantt_events';
import { NEED_TYPE } from '../../constants/Needs';

// import { requestError } from '../../helpers/notification';

const shouldUpdateSlices = (fields) =>
    fields.filter((item) => {
        const existence = ['allocatedNeed', 'startDate', 'endDate', 'calendarId'].indexOf(item) !== -1;
        console.log('🚀 ~ file: SliceContext.jsx:23 ~ fields.filter ~ existence:', existence);
        return existence;
    }).length > 0;

const CalendarNoWorkingAttribute = {
    hours: 'noWorkingHours',
    days: 'noWorkingDays',
    week: 'noWorkingWeek',
    month: 'noWorkingMonth',
};

const SliceContext = createContext();

const SliceContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const scaleConfigs = GanttConfigurations.scaleConfigs(generalTranslation);

    const [scale, setScale] = useState(null);
    const [activityCalendarSliceDictionary, setActivityCalendarSliceDictionary] = useState(null);
    const {
        processingGantt,
        planningSelected,
        activitiesDictionary,
        planningPreferences,
        calendarsDictionary,
        updatedDataActivityBroadcast,
    } = useContext(PlanningContext);

    const { activeTab } = useContext(TabContext);

    const showHistogram =
        activeTab?.tabType === TAB_TYPE.GANTT
            ? planningPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
            : activeTab?.tabType === TAB_TYPE.RESOURCE;

    const generateActivityCalendarSlice = useCallback(
        (activity, timeScale) => {
            const scaleUnit = xAxisDateFormat(generalTranslation)[timeScale].mainScaleUnit;
            const noWorkingPeriods =
                calendarsDictionary?.[activity.calendarId]?.[CalendarNoWorkingAttribute[scaleUnit]] ?? [];
            return computeActivityCalendarSlice(
                [new ActivitySlice(activity.startDate, activity.endDate)],
                noWorkingPeriods,
                scaleUnit,
                activity.startDate,
                activity.endDate
            );
        },
        [calendarsDictionary]
    );

    const updateActivityResourceVirtualTasks = useCallback(
        async (taskId, updatedData, isBroadcast = true, slices = []) => {
            if (
                window.ganttInstance &&
                planningPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
            ) {
                window.saveScrollState = false;

                try {
                    // Save the current scroll state
                    const scrollState = window.ganttInstance.getScrollState();
                    if (isBroadcast) {
                        const tasksToUpdate = window.ganttInstance.getTaskBy('serverId', updatedData.id, {
                            project: true,
                            task: true,
                            milestone: true,
                        });
                        // deleting resource virtual tasks
                        tasksToUpdate.forEach(({ id }) => {
                            window.ganttInstance.getChildren(id).forEach((childId) => {
                                // window.ganttInstance.silent(() => {
                                const child = window.ganttInstance.getTask(childId);
                                if (child.isResource) {
                                    window.ganttInstance.deleteTask(childId);
                                }
                                // });
                            });
                        });
                    }
                    // Get all existing tasks
                    const allTasks = window.ganttInstance.getTaskByTime();
                    const allLinks = window.ganttInstance.getLinks();

                    // Filter out the resource tasks we want to update
                    const tasksToKeep = allTasks.filter(
                        (task) =>
                            !(task.serverId === updatedData.id && task.isResource) ||
                            !(task.parentServerId === updatedData.id)
                    );

                    const newActivityCalendarSlices = isBroadcast
                        ? generateActivityCalendarSlice(updatedData, scale)
                        : slices;

                    if (isBroadcast) {
                        setActivityCalendarSliceDictionary((oldValue) => ({
                            ...oldValue,
                            [taskId]: newActivityCalendarSlices,
                        }));
                    }

                    const resourceViewModeObject = RESOURCE_VIEW_MODE(i18n);
                    if (planningPreferences?.gantt_parameters?.showResources === resourceViewModeObject.NEEDS.value) {
                        const activityAllocatedNeeds = updatedData.allocatedNeed || [];
                        const newResourceTasks = [];

                        const parentTasks = tasksToKeep.filter((task) => task.serverId === updatedData.id);
                        // Non-consumable needs
                        [
                            ...activityAllocatedNeeds.nonConsumable.global,
                            ...activityAllocatedNeeds.nonConsumable.planning,
                        ].forEach((allocatedNeed) => {
                            parentTasks.forEach(({ id, startDate, endDate, calendarId, type, serverId }) => {
                                newResourceTasks.push({
                                    ...formatResourceTask(
                                        id,
                                        newActivityCalendarSlices,
                                        allocatedNeed.needLabel,
                                        startDate,
                                        endDate,
                                        serverId,
                                        false
                                    ),
                                    quantity: allocatedNeed.quantity,
                                    calendarId,
                                    needType: NEED_TYPE.NON_CONSUMMABLE,
                                    parentType: type,
                                    class: `resource-task-${allocatedNeed?.needId || 'empty'}`,
                                });
                            });
                        });

                        // Consumable needs
                        [
                            ...activityAllocatedNeeds.consumable.global,
                            ...activityAllocatedNeeds.consumable.planning,
                        ].forEach((allocatedNeed) => {
                            parentTasks.forEach(({ id, startDate, endDate, calendarId, type, serverId }) => {
                                newResourceTasks.push({
                                    ...formatResourceTask(
                                        id,
                                        newActivityCalendarSlices,
                                        allocatedNeed.needLabel,
                                        startDate,
                                        endDate,
                                        serverId,
                                        false
                                    ),
                                    quantity: allocatedNeed.quantity,
                                    calendarId,
                                    needType: NEED_TYPE.CONSUMMABLE,
                                    parentType: type,
                                    class: `resource-task-${allocatedNeed?.needId || 'empty'}`,
                                });
                            });
                        });

                        // Combine existing tasks with new resource tasks
                        const updatedTasks = [...tasksToKeep, ...newResourceTasks];

                        // Update the Gantt chart with the new data
                        window.ganttInstance.clearAll();
                        window.ganttInstance.parse({ data: updatedTasks, links: allLinks });
                    }

                    window.saveScrollState = true;
                    if (isBroadcast) {
                        // Restore the scroll state after changes
                        setTimeout(() => {
                            window.ganttInstance.scrollTo(scrollState.x, scrollState.y);
                        }, 100);
                    }
                } catch (error) {
                    console.log('🚀 ~ file: SliceContext.jsx:133 ~ error:', error);
                }
            }
        },
        [planningPreferences, scale, calendarsDictionary]
    );

    const computeActivityCalendarSliceDictionary = useCallback(
        (timescale) => {
            if (showHistogram) {
                const dictionary = {};

                console.log('🚀 STEP 1 - COMPUTING CALENDAR SLICE DICTIONARY');
                // remove all resource tasks
                if (window.ganttInstance) {
                    window.ganttInstance.getTaskByTime().forEach(({ id, isResource }) => {
                        if (isResource) {
                            window.ganttInstance.silent(() => window.ganttInstance.deleteTask(id));
                        }
                    });
                }
                Object.values(activitiesDictionary).forEach((activity) => {
                    // if (activity.allocatedNeed)
                    const allAllocatedNeeds = activity.allocatedNeed
                        ? [
                              ...activity.allocatedNeed.nonConsumable.global,
                              ...activity.allocatedNeed.nonConsumable.planning,
                              ...activity.allocatedNeed.consumable.global,
                              ...activity.allocatedNeed.consumable.planning,
                          ]
                        : [];
                    if (allAllocatedNeeds.length > 0) {
                        dictionary[activity.id] = generateActivityCalendarSlice(activity, timescale);
                        updateActivityResourceVirtualTasks(activity.id, activity, false, dictionary[activity.id]);
                    }
                });
                setActivityCalendarSliceDictionary(dictionary);
                if (window.ganttInstance) {
                    // setTimeout(() => {
                    window.ganttInstance.render();
                    // }, 1000);
                }
            } else {
                setActivityCalendarSliceDictionary(null);
                console.log('AUTO SCROLLING SLICE');
                restoreScrollState(planningPreferences);
            }
        },
        [calendarsDictionary, activitiesDictionary, showHistogram, planningPreferences]
    );

    useEffect(() => {
        if (planningSelected && processingGantt === false) {
            const currentScale = scale || scaleConfigs[1].name;
            // console.log('🚀 ~ file: SliceContext.jsx:88 ~ useEffect ~ currentScale:', currentScale);
            timer('calcul des slices :', () => {
                computeActivityCalendarSliceDictionary(currentScale);
            });
        }
    }, [processingGantt, scale]);

    const reloadChart = () => {
        const currentScale = scale || scaleConfigs[1].name;
        computeActivityCalendarSliceDictionary(currentScale);
    };

    // Update du dictionnaire lors d'un notifier

    useEffect(() => {
        if (
            updatedDataActivityBroadcast &&
            !updatedDataActivityBroadcast.isNewTask &&
            shouldUpdateSlices(updatedDataActivityBroadcast.diffFields) &&
            showHistogram
        ) {
            console.log('🚀 ~ useEffect ~ showHistogram:', showHistogram);
            updateActivityResourceVirtualTasks(
                updatedDataActivityBroadcast.id,
                updatedDataActivityBroadcast.updatedData
            );
        }
    }, [updatedDataActivityBroadcast]);

    useEffect(() => {
        if (showHistogram) {
            if (planningPreferences?.gantt_scales) {
                setScale(planningPreferences?.gantt_scales);
            } else {
                setScale(scaleConfigs[1].name);
            }
        }
    }, [planningPreferences?.gantt_scales]);

    return (
        <SliceContext.Provider
            value={{
                scale,
                activityCalendarSliceDictionary,
                reloadChart,
            }}
        >
            {children}
        </SliceContext.Provider>
    );
};

SliceContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { SliceContext, SliceContextProvider };
