import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Button, List, Select, Table, Modal } from 'antd';
import { union } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { notificationError } from '../../helpers/notification';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import { generateString } from '../../helpers/string-helper';

const LinkImport = ({ links, onOk, mappingData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'export' });
    const importTranslation = useTranslation('translation', { keyPrefix: 'modal_import' }).t;
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const columnTranslation = useTranslation('translation', { keyPrefix: 'columns' }).t;
    const linksTranslation = useTranslation('translation', { keyPrefix: 'link_tab' }).t;
    const { timeUnits } = useContext(PlanningContext);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    let mapping = mappingData;
    const [tableData, setTableData] = useState([]);
    const [validationData, setValidationData] = useState([]);
    const generateCellId = (indexCol, indexRow) => `link-cell-${indexCol}-${indexRow}`;
    // Fields to choose for mapping

    // Fields to choose for mapping
    const mappingColumns = [];

    mappingColumns.push({
        value: 'activityPredecessorId',
        label: t('predecessor_id'),
    });
    mappingColumns.push({
        value: 'activitySuccessorId',
        label: t('successor_id'),
    });
    mappingColumns.push({
        value: 'linkType',
        label: generalTranslation('type'),
    });
    mappingColumns.push({
        value: 'decalage',
        label: linksTranslation('gap'),
    });
    mappingColumns.push({
        value: 'dayDefinitionName',
        label: linksTranslation('gap_unit'),
    });
    mappingColumns.push({
        value: 'linkCategoryName',
        label: linksTranslation('link_category'),
    });
    mappingColumns.push({
        value: 'nope',
        label: columnTranslation('unassigned'),
    });

    const computeColumns = (data) => {
        let headers = [];
        const tableColumns = [
            {
                title: '',
                key: 'table-index',
                width: 50,
                fixed: 'left',
                align: 'center',
                render: (datac, r, index) => index + 1,
            },
        ];
        // getting all headers
        data.forEach((link) => {
            headers = union(headers, Object.keys(link));
        });
        const defaultMapping = {};
        headers.forEach(
            (header) => {defaultMapping[header] = mappingColumns.find((val) => val.label === header)?.value || 'nope'}
        );
        if (!mapping) {
            mapping = defaultMapping;
        }
        // generating columns
        tableColumns.push(
            ...headers.map((header, indexCol) => ({
                title: () => {
                    let mappingValue = 'nope';
                    if (mapping[header]) {
                        mappingValue = mapping[header];
                    }
                    return (
                        <div className="flex flex-col">
                            <span className="text-center">{header}</span>
                            <Select
                                defaultValue={mappingValue}
                                options={mappingColumns}
                                onChange={(val) => {mapping[header] = val}}
                            />
                        </div>
                    );
                },
                dataIndex: header,
                ellipsis: true,
                shouldCellUpdate: false,
                width: 200,
                render: (text, record, indexRow) =>
                    text !== '' && (
                        <span
                            className="p-1 inline-block w-full overflow-hidden text-ellipsis"
                            id={generateCellId(indexCol, indexRow)}
                            style={{ background: validationData.color }}
                        >
                            {text}
                        </span>
                    ),
            }))
        );
        return tableColumns;
    };
    useEffect(() => {
        setColumns(computeColumns(links));
        setTableData(links);
    }, [links]);

    const LinkTypes = ['FD', 'DD', 'FF', 'DF'];
    const LinkMapping = {
        SF: 'FD',
        FF: 'FF',
        SS: 'DD',
        FS: 'FD'
    }
    // const LinkCategoryMapping = {
    //     NORMAL: 'FD',
    //     CONFLICT: 'FF',
    //     DOMINANT: 'DD',
    // }
    const validateCellData = (field, mappingField, mappingLabel, data, cellId, indexRow) => {
        let hasError = false;
        switch (mappingField) {
            case 'dayDefinitionName':
                if (data !== '' && Object.values(timeUnits).findIndex(({ name }) => name === data) === -1) {
                    hasError = true;
                }
                break;
            case 'linkType':
                if (data !== '' && LinkTypes.indexOf(data) === -1) {
                    hasError = true;
                }
                break;

            default:
                break;
        }
        if (hasError) {
            const cell = document.getElementById(cellId);
            if (cell) cell.style.backgroundColor = 'orange';
            return {
                cellId,
                message: importTranslation('line_error', {index: indexRow, excelField: field, mappingField: mappingLabel}),
            };
        }
        return undefined;
    };
    const verifyAndFormatData = useCallback(
        (data) =>
            new Promise((resolve) => {
                setLoading(true)
                let canImport = true;
                console.log('🚀 ~ verifyAndFormatData ~ mapping:', mapping);
                const selectedColumns = Object.values(mapping);
                // verifying required columns
                if (
                    selectedColumns.indexOf('activityPredecessorId') === -1 ||
                    selectedColumns.indexOf('activitySuccessorId') === -1 ||
                    selectedColumns.indexOf('linkType') === -1
                ) {
                    canImport = false;
                    notificationError(t('data_import'), importTranslation('link_required_fields_error'));
                }

                const columnsToTest = selectedColumns.filter((i) => i !== 'nope');
                if (new Set(columnsToTest).size !== columnsToTest.length) {
                    canImport = false;
                    notificationError(t('data_import'), t('column_duplicate_error'));
                }

                if (!canImport) {
                    setLoading(false)
                    return;
                }

                /* eslint-disable */
                const formattedData = [];
                const error = [];
                data.forEach((item, indexLine) => {
                    const activityObj = {};
                    // activityObj.categoryName = "Normal"
                    let cellId;
                    let currentColumn;
                    Object.keys(mapping).forEach((i, indexCol) => {
                        if (mapping[i] && mapping[i] !== 'nope') {
                            activityObj[mapping[i]] = item[i];
                            if (mapping[i] === 'linkType') {
                                activityObj[mapping[i]] = LinkMapping[item[i]]
                            }
                        }
                        currentColumn = i;
                        cellId = generateCellId(indexCol, indexLine);
                        const currentMapping = mappingColumns.find((item) => item.value === mapping[i])?.label;
                        const dataValidation = validateCellData(
                            currentColumn,
                            mapping[i],
                            currentMapping,
                            activityObj[mapping[i]],
                            cellId,
                            indexLine + 1
                        );
                        if (dataValidation) {
                            error.push(dataValidation);
                        }
                    });
                    formattedData.push(activityObj);
                });
                console.log('🚀 ~ data.forEach ~ formattedData:', formattedData);
                console.log("🚀 ~ LinkImport ~ validationData:", error)
                if (error.length > 0) {
                    setValidationData(error);
                    setLoading(false)
                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        content: <p>{importTranslation('verification_error')}</p>,
                        okText: importTranslation('continue_anyway'),
                        cancelText: generalTranslation('cancel'),
                        onOk: () => {
                          console.log('Continuer quand même', formattedData);
                            resolve ({ linkData: formattedData, mapping });
                        },
    
                        onCancel : () => {
                          console.log('Annuler');
                          return;
                        },
                      });
                    // notificationError(
                    //     t('data_import'),
                    // );
                } else {
                    setLoading(false)
                    resolve ({ linkData: formattedData, mapping });
                }
            }),
        [mapping]
    );

    const handleNextButtonClick = async () => {
        const importData = links.length > 0 ? await verifyAndFormatData(links) : { linkData: [], mapping };
        console.log('🚀 ~ handleNextButtonClick ~ importData:', importData);
        if (onOk && importData) {
            onOk(importData);
        }
    };

    const goToCell = (cellId) => {
        const element = document.getElementById(cellId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    return (
        <div className="w-full h-full flex flex-col">
            <div className={`flex flex-grow  ${validationData.length > 0 ? 'h-40' : 'overflow-auto'}`}>
                <div className={`h-full ${validationData.length > 0 ? 'w-3/4  overflow-auto' : ''}`}>
                    <Table
                        sticky
                        bordered
                        pagination={false}
                        className="task-import-table"
                        size="small"
                        columns={columns}
                        dataSource={tableData}
                    />
                </div>
                {validationData.length > 0 && (
                    <div className="w-1/4 ml-1 overflow-auto">
                        <List
                            size="small"
                            header={<label>{importTranslation('verification_result')}</label>}
                            bordered
                            dataSource={validationData}
                            renderItem={(item) => (
                                <List.Item
                                    key={generateString(7)}
                                    className="hover:text-primary cursor-pointer"
                                    onClick={() => goToCell(item.cellId)}
                                >
                                    {item.message}
                                </List.Item>
                            )}
                        />
                    </div>
                )}
            </div>
            <div className="flex justify-end mt-2">
                {/* <Button type="primary" onClick={goPrevious}>
                    Reveinr
                </Button> */}
                <Button type="primary" loading={loading} onClick={() => handleNextButtonClick()}>
                    {importTranslation('verify_and_continue')}
                </Button>
            </div>
        </div>
    );
};

export default LinkImport;
